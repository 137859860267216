[data-component="ChatInput"] {
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
  > .contentTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    background-color: rgba(0, 0, 20, 0.2);
  }

  .div_iz {
    height: 100%;
    width: 100%;
  }

  .converse_messages {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(100% - 154px);
    overflow-y: auto;
    padding: 16px;
  }

  .converse_footer {
    position: relative;
    width: 100%;
    height:86px;
    z-index: 999;
    padding: 16px;
    background-color: rgba(0, 0, 20, 0.2);
    //background-color: red;
    display: flex;
    .txt-input {
      //background-color: yellow;
      height: 100%;
      width: 100%;
    }
    .btn-send-container{
      //background-color: green;
      height: 100%;
      padding-left: 12px;
      .float-button-desing-mobile {
        height: 48px !important;
        width: 48px !important;
        color:white !important;
        margin-top: 3px;
      }
    }
  }
  
  .hide-pan {
    display: none;
  }

  .msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  .msg:last-of-type {
    margin: 0;
  }

  .msg-img {
    margin-right: 10px;
  }
  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .msg-info-name {
    margin-right: 10px;
    font-weight: 400;
  }

  .msg-info-time {
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
  }

  .msg-bubble {
    max-width: 80%;
    padding: 15px;
    border-radius: 8px;
    background: var(--left-msg-bg);
    font-size: 14px;
    line-height: 1.3;
  }

  .left-msg .msg-bubble {
    border-bottom-left-radius: 0;
    background-color: #3366FF;
  }

  .right-msg {
    flex-direction: row-reverse;
  }

  .right-msg .msg-bubble {
    background: var(--right-msg-bg);
    color: #fff;
    border-bottom-right-radius: 0;
    background-color: #222A45;
  }

  .right-msg .msg-img {
    margin: 0 0 0 10px;
  }

  .clock-pos {
    margin-top: 3px;
  }

  .space-left {
    margin-left: 6px;
  }

  .space-right {
    margin-right: 6px;
  }
}
