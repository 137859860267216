
@mixin AppearFadeIn($duration, $enterOpacity, $activeOpacity){
	will-change: opacity;

	&.Appear-appear{
		opacity: $enterOpacity;
	}
		
	
	&.Appear-appear.Appear-appear-active{
		transition-property: opacity;
		transition-duration: $duration;
		opacity: $activeOpacity;
	}
}
