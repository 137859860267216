// Colors
$primary: #5B6773;
$secondary: #2D3A4A;
$text: #FFF;
$black: #000;
$warn: #bf2117;
$bg-menu: #171E2C;
$bg-menu-transparent: rgba(23,30,44,0.75);
$bg-color:#3D3B3B;
$hover: #daebfa;
$action: #2a3a4b;
$disabled: #C4C4C4;
$selected-good: #30bd18;
