[data-component='Field'] {
	display:block;
	margin: 10px 0;
	width:100%;
	label {
		display: block;
		width: 100%;
		text-align: left;
		margin: 20px 0 10px;
		font-weight: bold;
	}

	input,select {
		-webkit-box-align: center;
		align-items: center;
		background-color: rgb(14, 22, 36);
		box-sizing: border-box;
		color: rgb(184, 199, 224);
		display: block;
		font-size: 14px;
		-webkit-box-pack: justify;
		border-color: rgb(32, 43, 61);
		border-radius: 3px;
		border-style: solid;
		transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;

		border-width: 2px;
		padding: 4px 10px;
		width: 100%;
	}
	
}
