[data-component ='ImageHeader'] {
  .header-back-color {
    background-color: #FFF9FF !important;
    height: 320px !important;
    background-image:url('/assets/images/illustrations/dashboardBackground.svg');
    background-size: cover;
  }

  .special-header-title {
    font-size: 46px;
    font-weight: 600;
  }

  .special-header-subtitle {
    font-size: 32px;
    font-weight: 600;
    color: black !important;
    text-align: center;
  }

  .containet-title {
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}