[data-component="MenuTopCustomer"] {
  height: 40px;
  width: 100%;
  background-color: #222a45;
  display: flex;
  justify-content: center;
  align-items: center;
  > .botoneraLeft {
    width: 20%;
    height: 100%;
    text-align: left;
    display: flex;
    align-items: stretch;
    margin-left: 5px;
  }
  /*  > .botoneraCenter {
    width: 60%;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: center;
    div {
      width: 100%;
      span {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  } */
  > .botoneraRight {
    width: 80%;
    height: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5px;
  }

  .tags span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    padding: 0 10px 0 10px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 15px;
    cursor: default;
    font-weight: 500;
  }

  .tags span.resalt {
    background-color: rgba(0, 0, 20, 0.2);
  }
}
