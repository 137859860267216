@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src:
		local('Roboto Light'),
		local('Roboto-Light'), url('/resources/fonts/Roboto-light-ext.woff2') format('woff2');
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src:
		local('Roboto Light'),
		local('Roboto-Light'),
		url('/resources/fonts/Roboto-light.woff2') format('woff2'),
		url('/resources/fonts/Roboto-light.ttf') format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src:
		local('Roboto'),
		local('Roboto-Regular'),
		url('/resources/fonts/Roboto-regular-ext.woff2') format('woff2');
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src:
		local('Roboto'),
		local('Roboto-Regular'),
		url('/resources/fonts/Roboto-regular.woff2') format('woff2'),
		url('/resources/fonts/Roboto-regular.ttf') format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src:
		local('Roboto Medium'),
		local('Roboto-Medium'),
		url('/resources/fonts/Roboto-medium-ext.woff2') format('woff2');
	unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src:
		local('Roboto Medium'),
		local('Roboto-Medium'),
		url('/resources/fonts/Roboto-medium.woff2') format('woff2'),
		url('/resources/fonts/Roboto-medium.ttf') format('ttf');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
