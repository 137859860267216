[data-component='ContentButton'] {
	display: flex;
	width:auto;
	height:auto;
	position:relative;
	.totalCounts{
		background: #fff;
		width: 16px;
		height: 16px;
		line-height: 15px;
		font-size: 10px;
		text-align: center;
		border-radius: 50%;
		color: #000;
		position: absolute;
		right: 0;
		top: 0;
		right: -1px;
    	top: -3px;
	}
	
}
[data-component='Button'] {

	display: flex;
	width:auto;
	height:auto;
	position:relative;
	.totalCounts{
		background: #fff;
		width: 16px;
		height: 16px;
		line-height: 15px;
		font-size: 10px;
		text-align: center;
		border-radius: 50%;
		color: #000;
		position: absolute;
		right: 0;
		top: 0;
		right: -1px;
    	top: -3px;
	}
	transition-property: opacity, background-color;
	transition-duration: 0.2s;
	margin: 0 7px;
	cursor: pointer;

	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	font-size: 24px;

	height: 40px;
	width: 40px;
	justify-content: center;
	align-items: stretch;
	&.transparent{
		border:unset;
		color: #FFF;
		background:transparent;
		width:auto;
		font-size:12px;
		text-align: left;
    	display: block;
		height:auto;
		vertical-align: top;
		display: inline-block;
    	text-align: left;
		i{
			font-size: 16px;
		}
		span{
			font-size: 12px;
			line-height: 18px;
			vertical-align: top;
		}
		&.active{
			background: rgba(255,255,255,0.15);
		}
		&:hover{
			color: #b8c7e0;
		}
	}
	&.next{
		-webkit-box-align: baseline;
        align-items: baseline;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        font-size: inherit;
        font-style: normal;
        height: 2.28571em;
        line-height: 2.28571em;
        max-width: 100%;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
        color: rgb(27, 38, 56) !important;
        background: rgb(76, 154, 255);
        border-radius: 3px;
        border-width: 0px;
        margin: 0px;
        padding: 0px 8px;
        text-decoration: none;
        // transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
        outline: none !important;
        &:hover{
            background: rgb(179,212,255);
        } 
		&.disabled{
			background: rgba(193, 188, 188, 0.5);
			color: #FFF!important;
			cursor: no-drop!important;
			&:hover{
            	cursor: no-drop!important;
        	}
		}
	}
	&.icon{	
		border:unset;
		color: #FFF;
		background:transparent;
    	border-radius: 5px;	
    		
		&.active{
			background: rgba(255,255,255,0.15);
		}
		&:hover{
			background: rgba(255,255,255,0.15);
		}
		
	}
	&.icon-button{
		color:#5e6d7a;
		background-color: #fff;
		border-radius: 50%;
		border: 1px solid #d1dbe8;
		&:hover{
			background-color: #daebfa;
    		border: 1px solid #daebfa;
		}
		
		&.active{
			background: #2a3a4b;
    		border: 1px solid #5e6d7a;
			color: #FFF;
		}
		&.call{
			color:#FFF;
			background-color: #bf2117;
			border: #bf2117;
			&:hover{
				background-color: #bf2117;
				border: #bf2117;
			}
		}
	}



	&.on {
		background-color: rgba(#fff, 0.7);
	}
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
		transition-duration: 0s;
	}
}


