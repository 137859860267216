[data-component="RoomPeer"] {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #3366ff;
	border-radius: 12px;
	overflow: hidden;
	&.speak {
		border: 2px solid purple;
	}
	> div {
		position: relative;
		width: 100%;
		height: 100%;
		> div {
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.noImage {
		background-image: url(/assets/images/illustrations/buddy.svg);
		position: absolute;
		left: 0;
		bottom: 0;
		background-position: bottom;
		background-size: auto 80%;
		background-repeat: no-repeat;
		border-radius: 8px;
	}
	.peerName {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		padding: 4px;
		border-radius: 4px;
		max-width: 80%;
		z-index: 99;
		display: grid;
		grid-template-columns: 1fr 20px;
		align-items: center;
		gap: 8px;
		height: auto;
		width: auto;
		svg {
			width: 16px;
		}
	}
}
