[data-component="Peer"] {
	flex: 100 100 auto;
	display: inline-block;
	.fullscreen {
		height: 100%;
		width: 100%;
	}
	&.invited {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0 0 1px;
	}
	&.speeker {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: auto;
		left: 0;
		top: 0;
	}
	&.interpreter {
		width: 100%;
		height: 100%;
		margin: auto;
		position: relative;
	}
	.incompatible-video {
		position: absolute;
		z-index: 2;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> p {
			padding: 6px 12px;
			border-radius: 6px;
			user-select: none;
			pointer-events: none;
			font-size: 15px;
			color: rgba(#fff, 0.55);
		}
	}
	
	.indicators {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 3px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: inherit;
		height: 30px;
		i {
			color: $primary;
			font-size: 20px;
			margin-left: 10px;
		}
	}
	.top-area {
		width: 100%;
		position: absolute;
		top: 0px;
		display: flex;
		flex-direction: row;
		z-index: 4;
	}
	.buttons-area {
		width: 50%;
		display: flex;
		flex-direction: column;
		padding-right: 8px;
		padding-top: 8px;
		align-items: flex-end;
	}
	.status-area {
		width: 50%;
		padding-left: 8px;
		padding-top: 8px;
	}
	.space-indicator {
		width: 32px;
		height: 32px;
		text-align: center;
		margin-bottom: 8px;
	}
	.space-indicator2 {
		width: 40px;
		height: 40px;
		text-align: center;
		margin-bottom: 8px;
	}
	.parpadeo {
		-webkit-animation-name: parpadeo;
		-webkit-animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
	}
	@-webkit-keyframes parpadeo {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	.indicator {
		width: 32px;
		height: 32px;
		background-color: rgba(0, 0, 20, 0.4);
		border-radius: 16px;
	}
	.icon-pos {
		margin-top: 7px;
	}
	.actions-icons {
		background-color: rgba(255, 255, 255, 0.2);
	}
}
