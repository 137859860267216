[data-component="ButtonTransparent"] {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  transition-property: opacity, background-color;
  transition-duration: 0.2s;
  margin: 0 7px;
  cursor: pointer;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  height: 46px;
  width: 46px;

  border:unset;
  color: $text;
  background:transparent;
      
  &.active{
    background: $primary;
  }
  &:hover{
    background: $primary;
  }

}
// #app-room-mobile-container {
//   [data-component="ButtonCircle"] {
//     color: red;
//   }
// }
