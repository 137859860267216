$colors: red, orange, yellow, green, blue, purple;
[data-component="ViewGrid"] {
  width: 100%;
  height: 100%;
  padding: 6px;

  :nth-of-type(element) {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    height: 100%;
    display: grid;
    // position: relative;
    transition: all 2s;
    //gap: 0.2rem;
    gap: 6px;
    // background-color: rgba(0, 0, 0, 0.2);
    .elementView {
      > div {
        height: 100%;
      }
      position: relative;
      &.invited {
        border: 2px solid orange;
        border-radius: 8px;
      }
      &.interpreter {
        border: 2px solid yellow;
        border-radius: 8px;
      }
      &.speeker {
        border: 2px solid green;
        border-radius: 8px;
      }
    }
  }
  .grid {
    &.element_1 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_2 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_3 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      :nth-child(1) {
        grid-row: 1/3;
      }
    }
    &.element_4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.element_5 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      :nth-child(1) {
        grid-row: 1/3;
      }
    }
    &.element_6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    &.element_7 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      :nth-child(1) {
        grid-row: 1/4;
      }
    }
    &.element_8 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      :nth-child(1) {
        grid-row: 1/3;
      }
    }
    &.element_9 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
    &.element_10 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      :nth-child(1) {
        grid-row: 1/4;
      }
    }
    &.element_11 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      :nth-child(1) {
        grid-row: 1/3;
      }
    }
    &.element_12 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
    &.speaker {
      border: 2px solid green;
    }
  }
  .column {
    .elementView {
      width: 100%;
      padding-top: 56.25%; //RATIO: 16:9
      position: relative;
      > div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        top: 0;
        left: 0;
      }
    }
  }
  .mobile {
    width: auto;
    display: flex;
    .elementView {
      min-width: 50%;
      position: relative;
      width: 100%;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
  }
  .customer {
    width: auto;
    display: flex;
    .elementView {
      //min-width: 50%;
      position: relative;
      width: 100%;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
  }
  .support {
    width: auto;
    display: flex;
    .elementView {
      min-width: 50%;
      position: relative;
      width: 100%;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
  }
  .row {
    &.element_1 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_2 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_3 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_4 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }
    &.element_5 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(5, 1fr);
      :nth-child(1) {
        grid-column: 1/3;
      }
    }
    &.element_6 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }
    &.element_7 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }
    &.element_8 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }
    &.element_9 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
    &.element_10 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      :nth-child(1) {
        grid-column: 1/4;
      }
    }
    &.element_11 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      :nth-child(1) {
        grid-column: 1/3;
      }
    }
    &.element_12 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }

  .hidden {
    display: none;
  }

  &.hiddenScrollX {
    overflow-x: hidden;
  }
}
