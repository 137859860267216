[data-component="Contacts"] {
  width: 100%;
  height: calc(100% - 62px);
  overflow: hidden;

  .search-content {
    padding: 16px;
    background-color: rgba(0, 0, 20, 0.05);
  }

  .div-scroll {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(100% - 122px);
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-top: 16px;
    z-index: 4;
  }

  .MuiExpansionPanelDetails-root {
    padding: 0 16px;
  }

  .contentTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    background-color: rgba(0, 0, 20, 0.2);
  }

  .contentSearch {
    align-items: center;
    display: flex;
    // margin: 16px;
    padding: 6px;
    .input {
      margin-left: 6px;
      margin-right: 6px;
    }
    .iconButton {
      padding: 6px;
    }
  }

  .input {
    width: 85%;
    .MuiOutlinedInput-input {
      padding: 10px 0;
    }
  }

  .extend-data {
    width: 100%;
  }

  .expansion-desing{
    padding-top: 3px;
    padding-bottom: 4px;
  }

  .tags span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    padding: 0 10px 0 10px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 15px;
    cursor: default;
    font-weight: 500;
    margin-right: 4px;
  }

  .tags span.resalt {
    background-color: rgba(0, 0, 20, 0.2);
  }
 
  .w-100 {
    width: 100%;
  }

  .w-30px {
    width: 30px;
    height: 30px;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .marg-6px {
    margin-left: 6px !important;
  }

  .expansion-desing-special{
    padding-top: 3px;
    padding-bottom: 4px;
    min-height: 32px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

}