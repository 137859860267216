[data-component="ButtonMicrophone"] {
  .desktop-pos {
    margin-right: 12px;
    margin-top: 7px;
  }
  .movile-pos {
    margin-top: 7px;
    margin-right: -12px;
  }
}
