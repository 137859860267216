[data-component='Forms'] {
	background: $COLOR_BG_2;
	position: relative;
	margin: auto;
	top: 10%;
	width: 600px;
	text-align: center;
	color: #000;
	z-index: 9999;
	padding: 30px;
	color: white;	
	border-radius:10px;
	max-height:80%;
	overflow: auto;
	&.small{
		width: 400px;
	}
	h2 {
		box-shadow: none;
		margin: 0;
		width: 100%;
		font-size: 20px;
		color: #b8c7e0;
		text-align: left;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.tabs{
		position: relative;
		display: block;
		font-weight: 500;
		list-style-type: none;
		margin: 0 0 20px;
		padding: 0;
		text-align: left;
		.tab{
			    color: #9fb0cc;
				cursor: pointer;
				position: relative;
				white-space: nowrap;
				width: auto;
				padding-right:10px;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				margin: 0px;
				overflow: hidden;
				display: inline-block;
				font-size: 12px;
				padding: 0 20px 10px 0;
			&:hover{
				color: rgb(179, 212, 255);
			}
			.lineSelect {
				display:none;
			}
			&.selected{
				color: rgb(76, 154, 255);
				&:hover{
					color: rgb(76, 154, 255);
				}
				.lineSelect {
					display:inline-block;
					background-color: rgb(76, 154, 255);
					bottom: 0px;
					content: "";
					height: 2px;
					left: 0;
					position: absolute;
					width: inherit;
					border-radius: 2px;
					margin: 0px;
					width:calc(100% - 20px);
				}
			}
		}
	}
	.line{
		background-color: rgb(13, 20, 36);
		bottom: 0px;
		content: "";
		height: 2px;
		position: absolute;
		width: inherit;
		border-radius: 2px;
		margin: 0px;
		width:100%;
	}
	label {
    	left: 10px;
    	width: 50%;
    	display: inline-flex;
	}
	input {
		background: white;
    	right: 10px;
    	width: 50%;
    	display: inline-flex;
	}
	li {
		margin: 20px;
		 select, input {
			background: white;
			display: inline-block;
			width: 28%;
			margin: 5px;
		}
		 button {
			display: inline-block;
			margin-right: 5px;
		}
			h4 {
			display: inline-block;
			}
	}
	.table{
		width:100%;
		display:block;
		color: #fff;
		font-size: 14px;
		.row{
			width:100%;
			display:block;
			margin: 0 3px;
			
			&.title{
				border-bottom: 1px solid #b8c7e0;
				padding:0 0 10px 0;
				font-weight:bold;
				color: #b8c7e0;
			}
			div{
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow:hidden;
				text-align:left;
				vertical-align:middle;
			}
			.col1{
				display:inline-block;
				width: 25%;
			}
			.col2{
				display:inline-block;
				width: 20%;
			}
			.col3{
				display:inline-block;
				width: 30%;
			}
			.col4{
				display:inline-block;
				width: 25%;
				padding: 4px 0;
				button{
					margin-left:5px;
				}
			}
			.userCol1{
				display:inline-block;
				width: 40%;
				padding: 0 0 10px 0;
			}
			.userCol2{
				display:inline-block;
				width: 40%;
				padding: 0 0 10px 0;
			}
			.userCol3{
				display:inline-block;
				width: 20%;
				text-align:center;
				vertical-align: sub;
				button{
					height:20px;
					i{
						font-size:30px;
						color: #f00;
					}
				}
				
			}
		}
	}
	.userTable{
		margin-bottom:50px;
	}
	.botonera{
		text-align:right;
		margin: 30px 0  10px;
	}
	 .content{
        box-shadow: none;
        padding: 0 0 14px;
		text-align:left;
		border-bottom: 1px solid #b8c7e0;
    	border-top: 1px solid #b8c7e0;
        > div{
            font-weight:bold;
            margin: 10px 0;
            >span{
                font-weight:400;
                display:inline-block;
                margin-left:10px;
            }
        }
       
    }
	.exit{
		position:fixed;
		right: 10px;
		top: 10px;
	}
}
