[data-component="StatusBar"] {
  display: flex;
  flex-direction: row;
  .conected-status-area2 {
    display: flex;
    flex-direction: row;
    margin-right: 6px;
    align-items: center;
  }
  .conected-status-area {
    display: flex;
    flex-direction: row;
    margin-right: 6px;
    align-items: center;
    background-color: rgba(0, 0, 20, 0.2);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    height: 41px;
    .text-status {
      font-size: 12px;
      margin-left: 6px;
      line-height: 1.2;
    }
  }
  .animate-hide {
    display: none;
  }
  .animate-size {
    width: 40px;
    height: 40px;
  }
  .animate-size2 {
    width: 24px;
    height: 24px;
  }
  .animate-size3 {
    width: 32px;
    height: 32px;
  }
  .cant-Peer {
    display: flex;
    flex-direction: row;
    margin-right: 6px;
    align-items: center;
    span {
      height: 30px;
      line-height: 30px;
      position: relative;
      padding: 0 10px 0 10px;
      font-size: 14px;
      text-decoration: none;
      border-radius: 15px;
      cursor: default;
      font-weight: 500;
      margin-right: 4px;
      background-color: rgba(0, 0, 20, 0.2);
    }
  }
}
