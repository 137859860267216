[data-component="Presentation"] {
  width: 100%;
  height: 100%;
  position: relative;
  .transparent-div{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
  }
  .react-player{
    width: 100%;
    height: 100%;
    position: relative;
  }
}