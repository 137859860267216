[data-component="ModalEnabledPeer"] {
	min-width: 500px;
	height: auto;
	font-size: 14px;
	line-height: 1.5;
	.viewCam {
		width: 300px;
		height: 200px;
		background: #fff;
		video {
			flex: 100 100 auto;
			height: 100%;
			width: 100%;
			object-fit: contain;
			user-select: none;
			transition-property: opacity;
			transition-duration: 0.5s;
			background-color: rgba(#0e0e0e, 1);
			transform: scaleX(-1);
		}
	}
}
