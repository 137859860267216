[data-component ='FloatButton'] {
  .right {
    right: 16px;
    position: fixed !important;
    bottom: 16px;
    z-index: 1000;
  }
  .right-with-panel {
    right: 366px !important;
  }
  .left {
    left: 16px;
    position: fixed !important;
    bottom: 16px;
    z-index: 1000;
  }
  .left-mobile {
    left: 12px !important;
    bottom: 12px !important;
  }
  .right-mobile {
    right: 12px !important;
    bottom: 12px !important;
  }
  .float-button-desing {
    height: 60px !important;
    width: 60px !important;
    color:white !important;
    z-index: 1000;
  }
  .float-button-desing-mobile {
    height: 48px !important;
    width: 48px !important;
    color:white !important;
    z-index: 1000;
  }
}