[data-component="ButtonCircle"] {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
  transition-property: opacity, background-color;
  transition-duration: 0.2s;
  margin: 0 7px;
  cursor: pointer;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 46px;
  width: 46px;
  border-radius: 50%;

  background: $action;
  border: 1px solid $primary;
  color: $text;
  &:disabled{
    background: #dddddd;
  }
  // &:hover {
  //   background-color: $hover;
  //   border: 1px solid $hover;
  // }

  &.active {
    color: $secondary;
    background-color: $text;
    border: 1px solid $primary;
  }
  &.warn {
    color: $text;
    background-color: $warn;
    border: $warn;
    &:hover {
      background-color: $warn;
      border: $warn;
    }
  }
}
// #app-room-mobile-container {
//   [data-component="ButtonCircle"] {
//     color: red;
//   }
// }
