[data-component="MenuBottomMobile"] {
	width: 100%;
	background-color: transparent;
	margin: 0px;
	padding: 0px;
	height: 72px;
	position: absolute;
  bottom: 0;
	left: 0;
	z-index: 1;
	.menu-normal {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		button{
			margin: 0 10px;
		}
	}
}