[data-component="ModalTerms"] {
  max-width: 38.4615rem;
  .content {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
    word-wrap: break-word;
    width: 100%;
    position: relative;
    height: 250px;
    .txt-terms{
      text-align: justify;
      line-height: 1.5em;
      padding: 2em 1.5em;
      background-color: rgba(5, 23, 44, 79%);
      color: #b8c7e0;
    }
    .space-scroll {
        margin-right: 18px;
    }
  }
}
