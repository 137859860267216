/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: $SCROLL_WIDTH;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;

}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #4fa5df;
  border-radius: $SCROLL_WIDTH;
}
.scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background-color: #4fa5df;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: $SCROLL_WIDTH;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #4fa5df;
}

.scrollbar::-webkit-scrollbar-button,
.scrollbar::-webkit-scrollbar-track-piece,
.scrollbar::-webkit-scrollbar-corner,
.scrollbar::-webkit-resizer { display: none; }

.scrollbar:hover::-webkit-scrollbar { display: initial; }
.scrollbar::-webkit-scrollbar { display: none; }

/* div box */
.scrollbar {
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
}
