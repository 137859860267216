div[data-component="PeerName"].hidden{
  display: none;
}
div[data-component="PeerName"] {
  
  .new-bottom-indicator {
    width: 100%;
    height: 34px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
  }
  .new-bottom-indicator-special {
    width: 100%;
    height: 34px;
    position: absolute;
    bottom: 152px;
  }
  .spacer {
    width: 16px;
  }
  .is-listener {
    background-color: #3366ff;
  }
  .is-speaker {
    background-color: #008000;
  }
  .is-interpreter {
    background-color: #fdf623;
  }
  .is-has-word {
    background-color: orange;
  }
  .icons {
    width: 34px;
    .area-detail {
      width: 34px;
      height: 34px;
      border-bottom-left-radius: 17px;
      padding-left: 9px;
      padding-top: 6px;
    }
    .area-detail2 {
      width: 32px;
      height: 34px;
      border-bottom-right-radius: 17px;
      padding-left: 8px;
      padding-top: 8px;
    }
  }
  .identificator-area {
    text-align: center;
    font-size: 12px;
    cursor: default;
    font-weight: 500;
    max-width: 100px;
    color: black;
  }

  .customer {
    transform: scale(0.75);
    margin-top: -6px;
    display: none;
    .identificator-area {
      border-bottom-left-radius: 17px;
      padding: 0px 8px;
    }
  }
}
