[data-component="RowContact"] {
  width: 100%;
  background-color: rgba(0, 0, 20, 0.2);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  .avatar-container{
    height: 48px;
    width: 48px;
    margin-right: 4px;
    display: flex;
    align-items: center;
  }
  .cont1 {
    width: 100%;
    height: 48px;
    .contbtn{
      width: 100%;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .contbtn2{
      width: 100%;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .swith-container{
        height: 16px;
        width: 80px;
        display: flex;
        flex-direction: row;
        margin-right: 4px;
        .spacer-indicator {
          height: 16px;
          width: 20px;
          text-align: center;
        }
      }
    }
  }
  .btnscontainer{
    height: 48px;
    width: 48px;
    margin-left: 4px;
    padding-top: 2px;
  }
  .top-spacer {
    margin-top: 5px;
    width: 100% !important;
    font-size: 14px !important;
  }
	.parpadeo {
		-webkit-animation-name:parpadeo;
		-webkit-animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
	}
	@-webkit-keyframes parpadeo {  
		0% { opacity: 1.0; }
		50% { opacity: 0.0; }
		 100% { opacity: 1.0; }
	}
}
