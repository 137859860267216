[data-component="ButtonMenuTopCustomer"] {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .detail-toggle {
    height: 5px;
    width: 80%;
    background-color: #3366ff;
    position: absolute;
    bottom: 0;
    border-radius: 2px;
  }
}
