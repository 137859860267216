[data-component='Peer'] {
	flex: 100 100 auto;
	
	
	display: inline-block;
	&.invited{
		position: relative;
		width: 100%;
    	height: 100%;
    	margin: 0 0 1px;
	}
	&.speeker{
		position: absolute;
		width: 100%;
    	height: 100%;
    	margin: auto;
		left:0;
		top:0;
	}
	&.interpreter{
		width: 100%;
    	height: 100%;
    	margin: auto;
		position:relative;
	}
	

	> .indicators {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 0;
		width: 150px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		> .icon {
			flex: 0 0 auto;
			margin: 4px;
			margin-left: 0;
			width: 32px;
			height: 32px;
			background-position: center;
			background-size: 75%;
			background-repeat: no-repeat;
			transition-property: opacity;
			transition-duration: 0.15s;

			
				opacity: 0.85;

			&.mic-off {
				background-image: url('/room/images/icon_remote_mic_white_off.svg');
			}

			&.webcam-off {
				background-image: url('/room/images/icon_remote_webcam_white_off.svg');
			}
		}
	}

	.incompatible-video {
		position: absolute;
		z-index: 2;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> p {
			padding: 6px 12px;
			border-radius: 6px;
			user-select: none;
			pointer-events: none;
			font-size: 15px;
			color: rgba(#fff, 0.55);
		}
	}
}
