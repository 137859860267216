[data-component="RoomContent"] {
    width: 100%;
    height: 100%;
   
    .wrapper {
      display: flex;
      align-items: stretch;
      position: relative;
      width: 100%;
      height: calc(100% - 62px);
      .container-super-grid2 {
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .super-empty {
          width: 100%;
          height: 100%;
        }
        > div {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        &.withLateral {
          grid-template-columns: 1fr 300px;
        }
      }
    }
    


  
    .bgColorPresentation {
      background-color: grey;
    }
    .bgColorSpeaker {
      background-color: green;
    }
    .bgColorInterpreter {
      background-color: yellow;
    }
    .bgColorParticipant {
      background-color: orange;
    }
    .container-grid {
      width: 100%;
      height: 100%;
      display: grid;
      justify-content: start;
      grid-template-columns: 75% 25%;
      grid-template-rows: 1fr 1fr 1fr;
      position: relative;
    }
    .panel {
      position: relative;
      height: 100vh;
      width: 350px;
      max-width: 350px;
      min-width: 350px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .speaker {
      grid-column: 1/3;
      grid-row: 1/4;
      &.grid1x3 {
        grid-column: 1/2;
        grid-row: 1/4;
      }
    }
  
    .speakerRigth,
    .interpreters,
    .participants {
      width: 100%;
    }
    .fullSize {
      height: 100%;
    }
    .half {
      height: 50%;
    }
    .quarter {
      height: 25%;
    }
    .oneThird {
      height: 33%;
    }
    .twoThird {
      height: 66%;
    }
    .threeQuarter {
      height: 75%;
    }
    .hidden {
      display: none;
    }
  
    .container-super-grid {
      width: 100%;
      height: 100%;
  
      display: grid;
  
      grid-template-rows: repeat(3, 1fr);
      &.withOutLateral {
        grid-template-columns: 75% 25%;
      }
      &.withLateral {
        grid-template-columns: 75% 25%;
      }
      > div {
        width: 100%;
        height: 100%;
      }
      > div:first-child {
        grid-row: span 3;
      }
    }
  }
  
  