@import "./variables";
@import "./mixins";
@import "./fonts";
@import "./reset";
@import "./generic";

html {
  height: 100%;
  box-sizing: border-box;
  // background-image: url('/room/images/body-bg-2.jpg');
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  background: #141e30;
  background: linear-gradient(to top, #243b55, #141e30);
  //font-family: 'Roboto';
  font-family: -apple-system, BlinkMacSystemFont, open_sanslight,
    "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: 300;
  overflow: hidden;
  font-size: 14px;
}

body {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  &:before {
    content: " ";
    width: 149px;
    height: 130px;
    // background-image:url("/room/images/solunika-logo.svg");
    position: absolute;
    opacity: 0.2;
    top: 0;
    left: 25px;
    z-index: 1;
    pointer-events: none;
  }
}

#app-container {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Components
  @import "./components/Room";
  @import "./components/Menu";
  @import "./components/MenuTop";
  @import "./components/Me";
  @import "./components/AdminPanel";
  @import "./components/Transmit";
  @import "./components/Record";
  @import "./components/Speeker";
  @import "./components/Invite";
  @import "./components/Interpreter";
  @import "./components/Peers";
  @import "./components/Peer";
  @import "./components/PeerView";
  @import "./components/Stats";
  @import "./components/Notifications";
  @import "./components/NetworkThrottle";
} // VIEWS
@import "./views/Forms";
@import "./views/Fields";

// Hack to detect in JS the current media query
#app-media-query-detector {
  position: relative;
  z-index: -1000;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 1px;

  // In desktop let it "visible" so elem.offsetParent returns the parent element
}
@import "./components/Inforoom";
@import "./components/Infospeaker";
@import "./components/Popover";
@import "./components/Button";
@import "./components/Presentation";
