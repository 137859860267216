@import "./../../../styles/room/stylus/variables.scss";
@import "./ButtonCircle";
@import "./ButtonTransparent";
#app-container {
  .PeerContentTODO {
    position: relative;
    @import "./PeerName";
  }
  [data-component="Speeker"] {
    position: relative;
    @import "./PeerName";
    bottom: 60px;
  }
  @import "./RoomDesktop";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./PeerName";
  @import "./Peer";
  @import "./WaitingState";
}

#app-room-mobile-container {
  position: fixed;
  width: 100%;
  height: 100%;
  @import "./RoomMobile";
  @import "./../../../styles/room/stylus/components/MenuTopMobile.scss";
  @import "./../../../styles/room/stylus/components/MenuBottomMobile.scss";
  @import "./../../../styles/room/stylus/components/PeerView.scss";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./WaitingState";
  @import "./PeerName";
  @import "./Peer";
  @import "./ImageClient";
}

#app-room-customer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  @import "./customer/RoomCustomer";
  @import "./customer/MenuTopCustomer";
  @import "./customer/ModalCustomer";
  @import "./customer/ButtonMenuTopCustomer";
  @import "./customer/ButtonCloseCustomer";
  @import "./../../../styles/room/stylus/components/PeerView.scss";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./WaitingState";
  @import "./PeerName";
  @import "./Peer";
  @import "./ImageClient";
  @import "./../../../styles/room/stylus/components/Me.scss";
}

#app-room-support-container {
  .PeerContentTODO {
    position: relative;
    @import "./PeerName";
  }
  [data-component="Speeker"] {
    position: relative;
    @import "./PeerName";
    bottom: 60px;
  }
  @import "./support/MenuTopSupport";
  @import "./support/ProductPanel";
  @import "./support/RoomSupport";
  @import "./StatusBar";
  @import "./RoomContent";  
  @import "./ViewGrid";
  @import "./ColumnListParticipants";
  @import "./PeerName";
  @import "./Peer";
  @import "./WaitingState";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Components
  // Components

  @import "../../room/stylus/components/Room";
  @import "../../room/stylus/components/Menu";
  @import "../../room/stylus/components/MenuTop";
  @import "../../room/stylus/components/Me";
  @import "../../room/stylus/components/AdminPanel";
  @import "../../room/stylus/components/Transmit";
  @import "../../room/stylus/components/Record";
  @import "../../room/stylus/components/Speeker";
  @import "../../room/stylus/components/Invite";
  @import "../../room/stylus/components/Interpreter";
  //@import "../../room/stylus/components/Peers";

  @import "../../room/stylus/components/PeerView";
  @import "../../room/stylus/components/Stats";
  @import "../../room/stylus/components/Notifications";
  @import "../../room/stylus/components/NetworkThrottle";
}

#app-room-hubeet-support-container {
  .PeerContentTODO {
    position: relative;
    @import "./PeerName";
  }
  [data-component="Speeker"] {
    position: relative;
    @import "./PeerName";
    bottom: 60px;
  }
  @import "./hubeetSupport/MenuTopSupport";
  @import "./hubeetSupport/ProductPanel";
  @import "./hubeetSupport/RoomSupport";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./ColumnListParticipants";
  @import "./RoomContent";  
  @import "./RoomPeer";
  @import "./PeerName";
  @import "./Peer";
  @import "./WaitingState";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Components
  // Components

  @import "../../room/stylus/components/Room";
  @import "../../room/stylus/components/Menu";
  @import "../../room/stylus/components/MenuTop";
  @import "../../room/stylus/components/Me";
  @import "../../room/stylus/components/AdminPanel";
  @import "../../room/stylus/components/Transmit";
  @import "../../room/stylus/components/Record";
  @import "../../room/stylus/components/Speeker";
  @import "../../room/stylus/components/Invite";
  @import "../../room/stylus/components/Interpreter";
  //@import "../../room/stylus/components/Peers";

  @import "../../room/stylus/components/PeerView";
  @import "../../room/stylus/components/Stats";
  @import "../../room/stylus/components/Notifications";
  @import "../../room/stylus/components/NetworkThrottle";
}

#app-room-interpreter-container {
  .PeerContentTODO {
    position: relative;
    @import "./PeerName";
  }
  [data-component="Speeker"] {
    position: relative;
    @import "./PeerName";
    bottom: 60px;
  }
  @import "./interpreter/MenuTopInterpreter";
  @import "./interpreter/RoomInterpreter";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./PeerName";
  @import "./Peer";
  @import "./WaitingState";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Components
  // Components

  @import "../../room/stylus/components/Room";
  @import "../../room/stylus/components/Menu";
  @import "../../room/stylus/components/MenuTop";
  @import "../../room/stylus/components/Me";
  @import "../../room/stylus/components/AdminPanel";
  @import "../../room/stylus/components/Transmit";
  @import "../../room/stylus/components/Record";
  @import "../../room/stylus/components/Speeker";
  @import "../../room/stylus/components/Invite";
  @import "../../room/stylus/components/Interpreter";
  //@import "../../room/stylus/components/Peers";

  @import "../../room/stylus/components/PeerView";
  @import "../../room/stylus/components/Stats";
  @import "../../room/stylus/components/Notifications";
  @import "../../room/stylus/components/NetworkThrottle";
}

#app-room-hubeet-interpreter-container {
  .PeerContentTODO {
    position: relative;
    @import "./PeerName";
  }
  [data-component="Speeker"] {
    position: relative;
    @import "./PeerName";
    bottom: 60px;
  }
  @import "./hubeetInterpreter/MenuTopInterpreter";
  @import "./hubeetInterpreter/RoomInterpreter";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./PeerName";
  @import "./Peer";
  @import "./WaitingState";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  // Components
  // Components

  @import "../../room/stylus/components/Room";
  @import "../../room/stylus/components/Menu";
  @import "../../room/stylus/components/MenuTop";
  @import "../../room/stylus/components/Me";
  @import "../../room/stylus/components/AdminPanel";
  @import "../../room/stylus/components/Transmit";
  @import "../../room/stylus/components/Record";
  @import "../../room/stylus/components/Speeker";
  @import "../../room/stylus/components/Invite";
  @import "../../room/stylus/components/Interpreter";
  //@import "../../room/stylus/components/Peers";

  @import "../../room/stylus/components/PeerView";
  @import "../../room/stylus/components/Stats";
  @import "../../room/stylus/components/Notifications";
  @import "../../room/stylus/components/NetworkThrottle";
}

#app-room-hubeet-customer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  
  



  @import "./hubeetCustomer/RoomCustomer";
  @import "./hubeetCustomer/MenuTopCustomer";
  @import "./hubeetCustomer/ModalCustomer";
  @import "./hubeetCustomer/ButtonMenuTopCustomer";
  @import "./hubeetCustomer/ButtonCloseCustomer";
  @import "./../../../styles/room/stylus/components/PeerView.scss";
  @import "./StatusBar";
  @import "./ViewGrid";
  @import "./ColumnListParticipants";
  @import "./RoomContent";  
  @import "./WaitingState";
  @import "./Peer";
  @import "./ImageClient";
  @import "./../../../styles/room/stylus/components/Me.scss";
  @import "./ColumnListParticipants";
  @import "./RoomContent";  
  @import "./RoomPeer";
}
@import "./MenuTopSpacer";
@import "./CountrySelect";
@import "./InviteInterpreterPanel";
@import "./Modal";
@import "./DisabledRoomModal";
@import "./ModalEnabledPeer";
@import "./ModalForUserSimple";
@import "./ModalCloseRoom";
@import "./ModalKickPeer";
@import "./ModalTerms";
@import "./../../../styles/room/stylus/components/ButtonMicrophone";
@import "./../../../styles/room/stylus/components/ButtonClose";
@import "./../../../styles/room/stylus/components/ButtonCamera";
@import "./../../../styles/room/stylus/components/ChatInput";
@import "./../../../styles/room/stylus/components/Contacts";
@import "./../../../styles/room/stylus/components/FloatMenu";
@import "./../../../styles/room/stylus/components/RowContact";
@import "./../../../styles/room/stylus/components/ButtonMenuTop";
@import "./ModalOnlineSupport";
