[data-component="Loading"] {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    .loading-position {
        display: flex;
        justify-content: center;
        align-content: center;
        overflow: hidden;
        height: 100px !important;
    }
    img {
        position: absolute;
        width: 1.9231rem;
        height: 1.9231rem;
        top: 0.6154rem;
    }
    div {
        width: 3.0769rem;
        height: 3.0769rem;
    }
}