.signup {
  background: #1A2038;
 
  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }
  .special-width-card {
    width: 660px !important;
  }
  .frame-detail {
    background-image: url('/assets/images/illustrations/frameDetail.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-color: $light-gray;
  }
  .frame-detail-mobile {
    background-image: url('/assets/images/illustrations/frameDetail.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
  }
  .recuadre {
    padding: 12px;
    border-radius: 8px;
    background-color: rgba(0, 0, 20, 0.2);
  }
  .room-name {
    font-size: 14px;
    line-height: 1.5;
  }
}