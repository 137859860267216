[data-component='Infospeaker'] {
    background-color: rgb(40, 52, 71);
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px, rgba(9, 30, 66, 0.31) 0px 0px 20px -6px;
    color: rgb(184, 199, 224);
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;
    pointer-events: auto;
    border-radius: 3px;
    outline: 0px;
    width:600px;
    min-height:300px;
    .header{
        box-shadow: none;
        padding: 20px 20px 14px;
        h4{
            font-size: 20px;
            font-style: inherit;
            font-weight: 500;
            letter-spacing: -0.008em;
            line-height: 1;
        }
    }
    .content{
        box-shadow: none;
        padding: 20px 20px 14px;
    }
    .next{
       -webkit-box-align: baseline;
        align-items: baseline;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        font-size: inherit;
        font-style: normal;
        height: 2.28571em;
        line-height: 2.28571em;
        max-width: 100%;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
        color: rgb(27, 38, 56) !important;
        background: rgb(76, 154, 255);
        border-radius: 3px;
        border-width: 0px;
        margin: 0px;
        padding: 0px 8px;
        text-decoration: none;
        transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
        outline: none !important;

        &:hover{
            background: rgb(179,212,255);
        } 
    }
}
