[data-component="Record"] {

  .is-hide {
    display: none !important;
  }

  .btn-space-top {
    margin-top: 16px;
  }

  .counter1 {
    font-size: 14px;
    padding-top: 16px;
    padding-left: 8px;
  }

  .counter2 {
    padding-right: 8px;
    font-size: 14px;
    padding-top: 16px;
  }

  .btn-space-top {
    margin-top: 16px;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .record-warning {
    font-size: 14;
    line-height: 1.5;
    margin-bottom: 16px;
    .detail{
      width: 15px;
      vertical-align: top;
    }
  }

  .animate-size2 {
    width: 45px;
    height: 45px;
  }

}