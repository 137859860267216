[data-component="MenuTopInterpreter"] {
  height: 62px;
  width: 100%;
  background-color: #222a45;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .botoneraLeft {
    width: 33%;
    text-align: left;
    display: flex;
    align-items: stretch;
    margin-left: 6px;
    padding-right: 12px;
  }

  > .botoneraRight {
    width: auto;
    text-align: right;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin-right: 6px;
    padding-right: 12px;
    gap: 0.25em;
  }
  .detail-bottom {
    width: calc(100% + 5px);
    height: 5px;
    left: 0px;
    position: absolute;
    margin-top: 55px;
    z-index: 1;
  }
  .tags span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    padding: 0 10px 0 10px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 15px;
    cursor: default;
    font-weight: 500;
  }

  .tags span.resalt {
    background-color: rgba(0, 0, 20, 0.2);
  }
}
