[data-component="ButtonMenuTop"] {
  height: 60px;
  width: 45px;
  padding-top: 8px;
  .detail {
    height: 5px;
    width: 100%;
    margin-top: 5px;
    border-radius: 2px;
  }
  .detail-toggle {
    height: 5px;
    width: 100%;
    background-color: #3366ff;
    margin-top: 5px;
    border-radius: 2px;
  }
  .parpadeo {
    -webkit-animation-name: parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }
  @-webkit-keyframes parpadeo {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
