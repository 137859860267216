$colors: red, orange, yellow, green, blue, purple;
[data-component="ColumnListParticipants"] {
  width: 100%;
  height: calc(100vh - 64px);
  box-sizing: border-box;
  padding: 6px;
  :nth-of-type(element) {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 2s;
    gap: 6px;
    &.column {
      .elementView {
        height: 170px;
        width: 100%;
      }
    }
    &.grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      &.cantElements_1 {
        .elementView {
          height: 100%;
          width: 100%;
        }
      }
      &.cantElements_2 {
        .elementView {
          height: 100%;
          width: 50%;
        }
      }
      &.cantElements_3 {
        .elementView {
          height: 33.33%;
          width: 50%;
        }
      }
      &.cantElements_4 {
        .elementView {
          height: 33.33%;
          width: 50%;
        }
      }
      &.cantElements_5 {
        .elementView {
          height: 33.33%;
          width: 50%;
        }
      }
      &.cantElements_6 {
        .elementView {
          height: 33.33%;
          width: 50%;
        }
      }
      &.cantElements_7 {
        .elementView {
          height: 33.33%;
          width: 33.33%;
        }
      }
      &.cantElements_8 {
        .elementView {
          height: 33.33%;
          width: 33.33%;
        }
      }
      &.cantElements_9 {
        .elementView {
          height: 33.33%;
          width: 33.33%;
        }
      }
      
    }
    .elementView {
      > div {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
}
