[data-component="MenuTopSpacer"] {
  width: min-content;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  .spacer {
    width: 1px;
    height: 50%;
    background-color: white;
    border-radius: 1em;
  }
}
