[data-component="RoomHubeetCustomer"] {
  width: 100%;
  height: 100%;
  .roomImage {
    max-height: 25%;
    max-width: 25%;
    position: absolute;
    opacity: 0.8;
    filter: alpha(opacity=20);
    top: 76px;
    left: 16px;
    z-index: 5;
    pointer-events: none;
  }
  .wrapper {
    display: flex;
    align-items: stretch;
    position: relative;
    width: 100%;
    height: calc(100% - 62px);
  }
  .container-super-grid2 {
    display: grid;
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
    }
    // grid-template-rows: 1fr;
    // grid-template-columns: 1fr;
    &.withLateral {
      //grid-template-columns: minmax(75%,1fr) minmax(auto,300px);
    }
  }

  .lateral {
    height: 100%;
    width: 100%;
    // grid-column: 2/3;
    // grid-row: 1/4;
    // overflow-x: hidden;
    // display: flex;
    //background-color: violet;
    .content {
      height: 100%;
      width: 100%;
      padding-right: 6px;
      overflow-x: hidden;
    }
  }

  // .divider-zones {
  //   height: 100%;
  //   width: 14px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }

  .bgColorPresentation {
    background-color: grey;
  }
  .bgColorSpeaker {
    background-color: green;
  }
  .bgColorInterpreter {
    background-color: yellow;
  }
  .bgColorParticipant {
    background-color: orange;
  }
  .container-grid {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: start;
    grid-template-columns: 75% 25%;
    grid-template-rows: 1fr 1fr 1fr;
    position: relative;
  }
  .panel {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .speaker {
    grid-column: 1/3;
    grid-row: 1/4;
    &.grid1x3 {
      grid-column: 1/2;
      grid-row: 1/4;
    }
  }

  .speakerRigth,
  .interpreters,
  .participants {
    width: 100%;
  }
  .fullSize {
    height: 100%;
  }
  .half {
    height: 50%;
  }
  .quarter {
    height: 25%;
  }
  .oneThird {
    height: 33%;
  }
  .twoThird {
    height: 66%;
  }
  .threeQuarter {
    height: 75%;
  }
  .hidden {
    display: none;
  }

  .container-super-grid {
    width: 100%;
    height: 100%;

    display: grid;

    grid-template-rows: repeat(3, 1fr);
    &.withOutLateral {
      grid-template-columns: 75% 25%;
    }
    &.withLateral {
      grid-template-columns: 75% 25%;
    }
    > div {
      width: 100%;
      height: 100%;
    }
    > div:first-child {
      grid-row: span 3;
    }
    // &.presentation_intepreters_speakers_participants {
    //   grid-template-areas:
    //     "presentation interpreters"
    //     "presentation speakers"
    //     "presentation participants";
    //   grid-template-rows: repeat(3, 1fr);
    //   grid-template-columns: 75% 25%;
    // }
    // &.intepreters_speakers_participants {
    //   grid-template-areas:
    //     "speakers interpreters"
    //     "speakers participants"
    //     "speakers participants";
    //   grid-template-rows: repeat(3, 1fr);
    //   grid-template-columns: 75% 25%;
    // }
  }
  .super-empty {
    // grid-area: empty;
  }
  .super-presentation {
    // grid-area: presentation;
  }
  .super-speaker {
    // grid-area: speakers;

    //grid-row: 1/4;
    overflow-y: hidden;
    .content {
      height: 100%;
    }
  }
  .super-interpreters {
    // grid-area: interpreters;
  }
  .super-participants {
    // grid-area: participants;
  }

  // width:100%;
  // height:100%;
  // .bgColorPresentation{
  //   background-color: grey;
  // }
  // .bgColorSpeaker {
  //   background-color: green;
  // }
  // .bgColorInterpreter{
  //   background-color: yellow;
  // }
  // .bgColorParticipant{
  //   background-color: orange;
  // }
  // .wrapper {
  //   display: flex;
  //   align-items: stretch;
  //   position: relative;
  //   width:100%;
  //   height:calc(100% - 62px);
  // }
  // .roomImage {
  //   max-height: 25%;
  //   max-width: 25%;
  //   position: absolute;
  //   opacity: 0.8;
  //   filter: alpha(opacity=20);
  //   top: 76px;
  //   left: 16px;
  //   z-index: 1;
  //   pointer-events: none;
  // }
  // .container-grid {
  //   width: 100%;
  //   height: 100%;
  //   display: grid;
  //   justify-content: start;
  //   grid-template-columns: 75% 25%;
  //   grid-template-rows: 1fr 1fr 1fr;
  //   position: relative;
  // }
  // .panel {
  //   position: relative;
  //   height: 100vh;
  //   // width:350px;
  //   // max-width:350px;
  //   // min-width: 350px;
  //   width:100%;
  //   max-width:100%;
  //   min-width: 100%;
  //   background-color: rgba(0,0,0,0.1);
  // }
  // .speaker {
  //   grid-column: 1/3;
  //   grid-row: 1/4;
  //   &.grid1x3 {
  //     grid-column: 1/2;
  //     grid-row: 1/4;
  //   }
  // }
  // .lateral {
  //   height: 100%;
  //   width: 100%;
  //   grid-column: 2/3;
  //   grid-row: 1/4;
  // }
  // .speakerRigth,
  // .interpreters,
  // .participants{
  //   width: 100%;
  // }
  // .fullSize {
  //   height:100%;
  // }
  // .half{
  //   height: 50%;
  // }
  // .quarter{
  //   height: 25%;
  // }
  // .oneThird{
  //   height: 33%;
  //  }
  //  .twoThird{
  //    height: 66%;
  //  }
  // .threeQuarter{
  //   height: 75%;
  // }
  // .hidden {
  //   display: none;
  // }
}
