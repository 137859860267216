[data-component='NetworkThrottle'] {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999;
	padding: 0 20px;
	background: rgba(#fff, 0.95);
	border-radius: 4px;
	box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
	font-family: 'Roboto';

	> h1.draggable {
		padding: 20px;
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 12px;
		color: #111;
		user-select: none;
		cursor: move;
	}

	> .inputs {
		> .row {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 20px;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}

			> .key {
				margin-right: 4px;
				flex: 0 0 auto;
				width: 100px;
				font-weight: 400;
				font-size: 11px;
				text-align: right;
				color: $COLOR_BG_1;
			}

			> .value {
				margin-left: 4px;
				flex: 0 0 auto;
				width: 100px;
				padding: 4px 8px;
				font-size: 11px;
				text-align: right;
				color: $COLOR_BG_2;
				border: none;
				background: rgba(#666, 0.1);
				border-radius: 2px;
				transition-property: opacity;
				transition-duration: 0.1s;

				&:disabled {
					opacity: 0.5;
				}

				&::placeholder {
					color: rgba($COLOR_BG_2, 0.25);
				}

				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
		}
	}

	> .buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;

		> button {
			margin: 0 4px;
			flex: 0 0 auto;
			width: 66px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			border-radius: 2px;
			font-weight: 400;
			font-size: 11px;
			color: #fff;
			text-align: center;
			user-select: none;
			cursor: pointer;
			transition-property: opacity;
			transition-duration: 0.1s;

			&:disabled {
				opacity: 0.5;
				cursor: default;
			}

			&.apply {
				background: #e9736e;
			}

			&.reset {
				background: lighten($COLOR_BG_1, 15%);
			}
		}
	}
}
