[data-component='Popover'] {
	.MuiPopover-paper {
		background-image: url('/assets/images/illustrations/frameDetail.svg') !important;
		background-repeat: no-repeat !important;
		background-position: top right !important;
		box-shadow: rgba(13, 20, 36, 0.81) 0px 0px 1px, rgba(13, 20, 36, 0.85) 0px 4px 8px -2px;
		box-sizing: content-box;
		color: rgb(184, 199, 224);
		max-height: 448px;
		max-width: 448px;
		z-index: 200;
		background: #242c46;
		border-radius: 3px;
		padding: 16px 24px;
		min-width:120px;
		ul{
			font-size: 1.2em;
			list-style-type: none;
			background-color: initial;
			margin: -16px -24px;
			padding: 4px 0;
			li{
				align-items: center;
				color: #B8C7E0;
				cursor: pointer;
				display: flex;
				font-size: 14px;
				height: auto;
				padding: 2px 12px;
				line-height:24px;
				position:relative;
				&.selected{
					background-color:#313d52;
					color: #b8c7e0;
					> .check{
						display:inline-block;
						&:before{
							color:rgba(48,189,24,0.75);
						}
					}
				}
				i{
					padding-right:10px;
					font-size:24px;
					
				}
				.check{
					display:none;
					position:absolute;
					right:0;
					top: 0;
				}
				img{
					padding-right:10px
				}
				&:hover{
					background-color:#313d52;
					color: #b8c7e0;
				}
			}
		}

	}
}