[data-component="Invite"] {
	text-align: center;
	.peers {
		width: auto;
		display: inline-block;
		margin: auto;
	}

	width: 200px;
	position: absolute;
	left: 0;
	height: auto;
	overflow: auto;
	max-height: 393px;
	background: $COLOR_BG_1;
	&.float {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99999;
		padding: 0;
		margin: 0;
		background: rgba(#fff, 0.95);
		border-radius: 4px;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		font-family: "Roboto";
		width: 200px;
		&.hidden {
			display: none;
		}
	}
	.no_interpreter {
		width: 200px;
		height: 100px;
		background: rgba(20, 30, 48, 0.65);
		padding: 30px 20px;
		color: #fff;
		font-weight: bold;
		opacity: 0.9;
		display: inline-block;
	}
}
