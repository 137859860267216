[data-component='Interpreter'] {
	&.float{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99999;
		padding: 0 20px;
		background: rgba(#fff, 0.95);
		border-radius: 4px;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		font-family: 'Roboto';
		width: 300px;
		height: 200px;
	}
	.no_interpreter{
		width: 300px;
		height: 200px;
		background: $COLOR_BG_2;
		span{
			box-shadow: none;
			margin: 0;
			width: 100%;
			font-size: 20px;
			color: #b8c7e0;
			text-align: left;
			font-weight: bold;
			margin-bottom: 20px;
		}
	}
	
}
