[data-component="Transmit"] {
  .btn-space-top {
    margin-top: 16px;
  }
  .btn-space-bottom {
    margin-bottom: 16px;
  }
  .custom-range {
    -webkit-appearance: none;
    width: 100%;
    margin-top: 16px;
  }

  input[type=range]::-webkit-slider-thumb:hover {
    background-color: #3366FF;
  } 

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 2px;
    cursor: pointer;
    background-color: #2B48A2;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid #222A45;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    max-width: 80px;
    position: relative;
    bottom: 9px;
    -webkit-transition: border 1000ms ease;
    transition: border 1000ms ease;
    background-color: #2B48A2;
  }

  input[type=range]::-webkit-slider-thumb:active {
    height: 24px;
    width: 24px;
    bottom: 11px;
    background-color: #3366FF;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .video-container {
    position: relative;
    .transmit-mask {
      position: absolute;
      width: 100%;
      z-index: 8;
      height: 100%;
    }
  }

  .counter1 {
    padding-right: 10px;
    font-size: 14px;
    padding-top: 16px;
  }

  .counter2 {
    padding-left: 10px;
    font-size: 14px;
    padding-top: 16px;
  }

}