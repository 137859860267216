[data-component='Me'] {
	&.fullScreen{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		padding: 0;
		//background: rgba(#fff, 0.95);
		//box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		//font-family: 'Roboto';
		width: 100%;
		height: 100%;
		box-shadow: none;
    background-color: transparent;
	}
	&.float{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99999;
		padding: 0 20px;
		background: rgba(#fff, 0.95);
		border-radius: 4px;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		font-family: 'Roboto';
		width: 300px;
		height: 200px;
	}
	
	> h1.draggable {
		padding: 20px;
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 12px;
		color: #111;
		user-select: none;
		cursor: move;
	}
	> .controls {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		pointer-events: none;

		> .button {
			flex: 0 0 auto;
			margin: 4px;
			margin-left: 0;
			border-radius: 2px;
			pointer-events: auto;
			background-position: center;
			background-size: 75%;
			background-repeat: no-repeat;
			background-color: rgba(#000, 0.5);
			cursor: pointer;
			transition-property: opacity, background-color;
			transition-duration: 0.15s;

			
				width: 28px;
				height: 28px;
				opacity: 0.85;

				&:hover {
					opacity: 1;
				}
			

			&.unsupported {
				pointer-events: none;
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}

			&.on {
				background-color: rgba(#fff, 0.85);
			}

			&.mic {
				&.on {
					background-image: url('/room/images/icon_mic_black_on.svg');
				}

				&.off {
					background-image: url('/room/images/icon_mic_white_off.svg');
					background-color: rgba(#d42241, 0.7);
				}

				&.unsupported {
					background-image: url('/room/images/icon_mic_white_unsupported.svg');
				}
			}

			&.webcam {
				&.on {
					background-image: url('/room/images/icon_webcam_black_on.svg');
				}

				&.off {
					background-image: url('/room/images/icon_webcam_white_on.svg');
				}

				&.unsupported {
					background-image: url('/room/images/icon_webcam_white_unsupported.svg');
				}
			}

			&.change-webcam {
				&.on {
					background-image: url('/room/images/icon_change_webcam_black.svg');
				}

				&.unsupported {
					background-image: url('/room/images/icon_change_webcam_white_unsupported.svg');
				}
			}

			&.share {
				&.on {
					background-image: url('/room/images/icon_share_black_on.svg');
				}

				&.off {
					background-image: url('/room/images/icon_share_white_on.svg');
				}

				&.unsupported {
					background-image: url('/room/images/icon_share_white_unsupported.svg');
				}
			}
		}
	}
}
