[data-component='Peers'] {
	min-height: 100%;
	width: 100%;

	
		width: 100%;
		padding: 40px 0 220px 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		align-content: center;
	
	> .primary {
		background:red;
		height:100%;
		width:100%;
		position:absolute;
		left:0;
		top:0;
		
			background:green;
		
	}
	> .secondary {
		background:blue;
		height:300px;
		width:300px;
		position:absolute;
		right:50px;
		bottom:100px;
		
		
	}
	> .peer-container {
		overflow: hidden;

		@include AppearFadeIn(1000ms,0,1);

		
		flex: 0 0 auto;
		height: 382px;
		width: 450px;
		margin: 6px;
		border: 1px solid rgba(#fff, 0.15);
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		transition-property: border-color;
		transition-duration: 0.35s;

		&.active-speaker {
			border-color: #fff;
		}
		
	}
}
