[data-component="ImageClient"] {
  height: auto;
  position: absolute;
  opacity: 0.2;
  filter: alpha(opacity=20);
  top: 60px;
  left: 5px;
  z-index: 1;
  pointer-events: none;
  
  @media all and (orientation: landscape) {
    max-height: 30vh;
    img {
      max-height: 25vh;
      width: auto;
    }
  }

  @media all and (orientation: portrait) {
    max-width: 40vw;
    img {
      max-width: 40vw;
      height: auto;
    }
  }
}
