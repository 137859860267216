[data-component="Menu"] {
	position: absolute;
    height: 50px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgba(20,30,48,0.65);
    padding: 10px 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    height: 60px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: stretch;
    -moz-box-align: stretch;
    -o-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
	align-items: stretch;
	
	margin: 0px;
	padding: 0px;
	height: 76px;
	background-color: transparent;
	position: absolute;
	width: 100%;
	.app-menu {
		z-index: 3;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.app-menu-small {
		width: calc(100% - 350px) !important;
	}
}
