[data-component="ModalOnlineSupport"] {
  text-align: center;
  padding: 4em;
  box-shadow: rgba(13, 20, 36, 0.81) 0px 0px 1px,
    rgba(13, 20, 36, 0.85) 0px 4px 8px -2px;
  box-sizing: content-box;
  overflow: hidden;
  &.background {
    background-image: url("/assets/images/illustrations/frameDetail.svg") !important;
    background-repeat: no-repeat !important;
    background-position: top right !important;
  }
}
