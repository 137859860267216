[data-component="Room"] {
	position: relative;
	height: 100%;
	width: 100%;

	
	

	> .state,
	> .stateMicrophone,
	> .stateHand {
		position: fixed;
		z-index: 100;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		// background-color: $COLOR_BG_1;
		

		top: 20px;
		right: 20px;
		width: 124px;
		

		&.hidden {
			display: none;
		}
		> .icon {
			flex: 0 0 auto;
			border-radius: 100%;

			margin: 6px;
			margin-right: 0;
			height: 16px;
			width: 16px;

			&.new,
			&.closed {
				background-color: rgba(#aaa, 0.5);
			}

			&.connecting {
				animation: Room-info-state-connecting 0.75s infinite linear;
			}

			&.connected {
				background-color: rgba(#30bd18, 0.75);
			}
		}

		> .text {
			flex: 100 0 auto;
			user-select: none;
			pointer-events: none;
			text-align: center;
			text-transform: uppercase;
			font-family: "Roboto";
			font-weight: 400;
			color: rgba(#fff, 0.75);

			font-size: 12px;
		}
	}
	> .stateMicrophone {
		top: 80px;
		right: 20px;
		width: 124px;

		.icon {
			color: #bf2117;
			&.active {
				color: rgba(48, 189, 24, 0.75);
			}
		}
	}
	> .stateHand {
		top: 50px;
		right: 20px;
		width: 124px;

		.icon {
			color: #bf2117;
			&.active {
				color: rgba(48, 189, 24, 0.75);
			}
		}
	}
	> .room-link-wrapper {
		pointer-events: none;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;

		> .room-link {
			width: auto;
			background-color: $COLOR_BG_1;
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
			box-shadow: 0px 3px 12px 2px rgba(#111, 0.4);

			> a.link {
				display: block;
				user-select: none;
				pointer-events: auto;
				text-transform: uppercase;
				font-family: "Roboto";
				font-weight: 400;
				color: rgba(#fff, 0.75);
				cursor: pointer;
				text-decoration: none;
				transition-property: color;
				transition-duration: 0.1s;

				padding: 10px 20px;
				font-size: 12px;

				&:hover {
					color: #fff;
					text-decoration: underline;
				}
			}
		}
	}

	> .me-container {
		position: fixed;
		z-index: 100;
		overflow: hidden;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		transition-property: border-color;
		transition-duration: 0.2s;

		&.active-speaker {
			border-color: #fff;
		}

		height: 250px;
		width: 294px;
		bottom: 60px;
		left: 20px;
		border: 1px solid rgba(#fff, 0.15);
	}

	> .sidebar {
		position: fixed;
		z-index: 101;
		top: calc(50% - 60px);
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		left: 20px;
		width: 36px;
		margin-top: -60px;

		> .button {
			flex: 0 0 auto;
			margin: 4px 0;
			background-position: center;
			background-size: 75%;
			background-repeat: no-repeat;
			background-color: $COLOR_BG_1;
			cursor: pointer;
			transition-property: opacity, background-color;
			transition-duration: 0.2s;
			border-radius: 100%;

			height: 36px;
			width: 36px;

			&.on {
				background-color: rgba(#fff, 0.7);
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
				transition-duration: 0s;
			}

			&.hide-videos {
				background-image: url("/room/images/icon_video_white_on.svg");

				&.on {
					background-image: url("/room/images/icon_video_black_off.svg");
				}
			}

			&.mute-audio {
				background-image: url("/room/images/icon_volume_white_on.svg");

				&.on {
					background-image: url("/room/images/icon_volume_black_off.svg");
				}
			}

			&.restart-ice {
				background-image: url("/room/images/icon_restart_ice_white.svg");
			}
		}
	}
}

@keyframes Room-info-state-connecting {
	50% {
		background-color: rgba(orange, 0.75);
	}
}
