* {
	box-sizing: border-box;
	outline: none;
}

body {
	background: none;
	line-height: 1;
}

input {
	padding: 0;
	background-color: transparent;
}
