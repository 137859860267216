[data-component="MyCompanyLists"] {
  .new-table-row {
    border-bottom: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px !important;
  }
  .new-table-row > .api-key {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .new-table-header {
    border-bottom: 0px !important;
  }

  .icon-space {
    width: 44px !important;
  }

  .switch-space-action {
    width: 170px !important;
  }

  .switch-space-common-data {
    width: 220px !important;
  }

  .switch-space-type {
    width: 180px !important;
  }

  .switch-space-rol {
    width: 150px !important;
  }

  .switch-space {
    width: 100px !important;
  }

  .table-row-special:hover {
    background-color: rgba(255, 255, 255, 0.03) !important;
  }

  tr td:first-child {
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-bottomleft: 8px;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
  }

  tr td:last-child {
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
  }
}
