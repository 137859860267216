[data-component='Inforoom'] {
    background-color: rgb(40, 52, 71);
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px, rgba(9, 30, 66, 0.31) 0px 0px 20px -6px;
    color: rgb(184, 199, 224);
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;
    pointer-events: auto;
    border-radius: 3px;
    outline: 0px;
    width:600px;
    max-width: 100%;
    min-height:300px;
    .headerInfoRoom{
        box-shadow: none;
        padding: 20px 20px 14px;
        h2{
            box-shadow: none;
            margin: 0;
            width: 100%;
            font-size: 20px;
            color: #b8c7e0;
            text-align: left;
            font-weight: bold;
        }
    }
    .content{
        box-shadow: none;
        padding: 20px 20px 14px;
        div     {
            font-weight:bold;
            margin:10px 0;
            span{
                font-weight:400;
                display:inline-block;
                margin-left:10px;
            }
        }
        p{
            height:70px;
            overflow:hidden;
        }
    }
    .botonera{
        text-align:right;
        width:100%;
        button{
            margin-left:10px;
        }
    }
}
