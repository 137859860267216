[data-component="AdminPanel"] {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .contentTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    background-color: rgba(0, 0, 20, 0.2);
  }

  .div-scroll {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(100% - 108px);
    overflow-y: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-top: 16px;
    z-index: 4;
  }

  .input {
    width: 85%;
    .MuiOutlinedInput-input {
      padding: 10px 0;
    }
  }

  .MuiExpansionPanelDetails-root{
    display:block;
  }

  .expansion-desing{
    padding-top: 3px;
    padding-bottom: 4px;
  }
}
